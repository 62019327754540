import { cooperationPartner } from '@/config/carriers/carriersConfig'
import { IndexContext } from '@/src/pages'
import { IKV } from '@/src/types'
import classNames from 'classnames'
import Image from 'next/image'
import React, { memo, useContext } from 'react'
import { Col, Row } from 'react-bootstrap'
import CustomImage from 'src/components/Image'
import useI18n from 'src/hooks/use_i18n'
import { formatCarrierName } from 'src/utils/carriers'
import { filterCarriersName } from 'src/utils/carriers_info'
import { toFilterSpace } from 'src/utils/tools'
import styles from './index.module.scss'

interface CoopCarrierSectionProps {
  mdSize?: number
  smSize?: number
  xsSize?: number
  row: number
  carriers: IKV[]
  onCarrierClick: (name: string) => void
}

// 拆分行模块，方便维护
const RowTableDom: React.FC<CoopCarrierSectionProps> = ({ mdSize, smSize, xsSize, row, carriers, onCarrierClick }) => {
  const { $t, activeLocale: locale } = useI18n()
  const { country } = useContext(IndexContext)
  const isCN = country?.toLowerCase() === 'cn'
  return (
    <Row>
      {carriers?.map((ele) => {
        // 优先判断是否brand合作，再判断是否有特殊合作，然后查询carriers的运输商
        const _targetCoopPartner = Object.values(cooperationPartner).find((item) => item.key === ele?.key) || null
        const _targetHref = isCN
          ? `/${locale}/brand/${formatCarrierName(ele?.[`_name_${locale}`] || ele?._name)}`
          : _targetCoopPartner?._url || `/${locale}/carriers/${filterCarriersName(toFilterSpace(ele?._name))}`
        // 判断是否存在特殊字段需要新开页，目前只有特殊合作需要校验
        const _isNewBlank = _targetCoopPartner ? _targetCoopPartner.isBlank : false
        return (
          ele && (
            <Col key={ele.key} md={mdSize || 4} sm={smSize || 4} xs={xsSize || 12} className="p-0">
              <div
                className={classNames('fade-from-bottom', !isCN ? styles.imgCover : '', styles['coop-carrier-item'])}
                data-animation-in
              >
                <a
                  className="cursor-pointer d-flex justify-content-center"
                  href={_targetHref}
                  target={_isNewBlank ? '_blank' : '_self'}
                  onClick={() => onCarrierClick(formatCarrierName(ele._name))}
                >
                  {isCN ? (
                    <Image
                      src={ele.indexLogo || `/assets/images/coop_carrier/${ele.key}.svg`}
                      alt={ele[`_name_${locale}`] || ele._name}
                      fill
                      className={styles['e-logo']}
                    />
                  ) : (
                    <>
                      <CustomImage
                        loading="lazy"
                        alt={ele[`_name_${locale}`] || ele._name}
                        width={120}
                        height={120}
                        isWebp={false}
                        defaultType={'webp'}
                        src={`/assets/images/coop_carrier/rows_0${row}/${_targetCoopPartner?.key || ele.key}`}
                        className={styles['e-logo']}
                        errorSrc={`/assets/images/coop_carrier/rows_0${row}/${_targetCoopPartner?.key || ele.key}`}
                      />
                    </>
                  )}
                  <span className={styles.mask}></span>
                  <span className={styles.action}>
                    <span className={styles.name}>{ele[`_name_${locale}`] || ele._name}</span>
                    <span className={styles.btns}>{$t('ResWwwPHome.global.__track_trackBtn')}</span>
                  </span>
                </a>
              </div>
            </Col>
          )
        )
      })}
    </Row>
  )
}

export default memo(RowTableDom)
